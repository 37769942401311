import React from 'react';
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const BlockquoteContainer = styled.blockquote`
    font-style: italic;
    border: 0.1rem solid #3e545e;
    border-radius: 1rem;
    padding: 2.5rem;
`

const NewsUpdates = () => {
  return (
    <div>
      <h2><span className="font-secondary">Unleaving</span> <a href="https://store.steampowered.com/bundle/45651/Unleaving_Artistry_Edition/">Artistry</a> and <a href="https://store.steampowered.com/bundle/45848/Unleaving_Collectors_Edition/">Collectors Edition</a> are available now!</h2>
      <ul>
        <li>Unleaving Artistry Edition includes the full game and a digital artbook on Steam!</li>
        <li>Unleaving Collector Edition includes the full game, the original soundtrack, and a digital artbook on Steam!</li>
      </ul>
      <BlockquoteContainer>"Unleaving is a 'playable art gallery'—it truly deserves recognition for Best Art Direction." <a href="https://mobilesyrup.com/2024/11/23/the-game-awards-2024-honourable-mentions-1000xresist-lil-guardsman-mouthwashing//"> Mobile Syrup</a></BlockquoteContainer>
      <StaticImage
            className="image"
            src="../assets/images/02-Unleaving_Absurd_cicada_Appearance.jpg"
            alt="Unleaving video game - hand painted - cicada / moth mother nature"
            layout="fullWidth"
            placeholder="blurred"
            loading="eager"
        />
      <p>Experience Unleaving on <a href="https://store.steampowered.com/app/1076720/">Steam</a> for PC & MacOs, <a href="https://store.epicgames.com/en-US/p/unleaving-9d452e"><span>Epic</span></a> store, <a href="https://www.xbox.com/en-ca/games/store/unleaving/9mz83x8td09r">Xbox!</a></p>
      <p>We're thrilled that orangutan matter and <a href="https://www.xbox.com/en-CA/games/id">ID @ Xbox</a> has partnered to bring Unleaving to <span className="font-secondary"><a href="https://www.xbox.com/en-ca/games/store/unleaving/9mz83x8td09r">Xbox!</a></span></p>
      <p></p>
      <BlockquoteContainer>"The power of the art and the music to bring me to such emotion...in a video game? This game certainly hit me in ways that I don’t recall experiencing before" ~ <a href="https://thegamingoutsider.com/2024/03/27/unleaving-pc-review/mszymanski/">Marc, The Gaming Outsider</a></BlockquoteContainer>
      <BlockquoteContainer>"Great game. The artwork is awesome. I cried at the end. The music is incredible." <a href="https://steamcommunity.com/id/GAME_EMOTION/recommended/1076720/"> Game_Emotion</a></BlockquoteContainer>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/ykVY1xtq0rg?si=5KHDEYaLJFFGDki-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  );
};

export default NewsUpdates;